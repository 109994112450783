import { APIConfig } from '../Common/Configurations/APIConfig';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import auth from '../Common/Configurations/Auth';
import { handleSuccess, handleError } from '../Common/Layouts/CustomAlerts';
const initialLoginValues = {
  email: '',
  password: '',
};
export default function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialLoginValues);
  const [errors, setErrors] = useState({});
const [loading,setLoading]=useState(false);
  const applicationAPI = (
    url = APIConfig.APIACTIVATEURL + APIConfig.LOGINUSER
  ) => {
    return {
      userlogin: (newRecord) => axios.post(url, newRecord),
    };
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    temp.email = values.email == '' ? false : true;
    temp.password = values.password == '' ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x == true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      try {
        initialLoginValues.email = values.email;
        initialLoginValues.password = values.password;
        checkUser(initialLoginValues);
      } catch (err) {
        handleError('Error' + err);
        setLoading(false);
      }
    } else {
      setLoading(false);
      handleError('Please check the mandatory fields');
    }
  };
  const checkUser = (loginData) => {
    applicationAPI()
      .userlogin(loginData)
      .then((res) => {
        if (res.data.statusCode === 200) {
          handleSuccess('Login Success');
          clearForm();
          setLoading(false);
          auth.ulogin(() => {
            localStorage.setItem('userId', res.data.userId);
            localStorage.setItem('userToken', res.data.token);
            localStorage.setItem('tokenexpiration', res.data.expiration);
            localStorage.setItem('roleName', res.data.role);
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('referenceId', res.data.referenceId);
            {
              if (res.data.role === 'Infinity') {
                navigate('/superadmin/dashboard');
              } else if (res.data.role === 'Collector') {
                navigate('/collectordashboard');
              } else if (res.data.role === 'Client') {
                navigate('/clientdashboard');
              } else if (res.data.role === 'Buyer') {
                navigate('/buyerdashboard');
              }
              else if (res.data.role === 'ClientUser') {
                navigate('/pickuprequests');
              }
              else if (res.data.role === 'CollectorUser') {
                navigate('/collectoruserrequests');
              }
            }
          });
        }
        else {
          setLoading(false);
          handleError(res.data.data);
        }
      })
      .catch(function (e) {
        setLoading(false);
        handleError('Please check the credentials');
      });
  };
  function clearForm() {
    values.email = '';
    values.password = '';
  }
  useEffect(() => {
    if (localStorage.getItem('userToken') !== '') {
      if (CheckExpirationTime()) {
        if (localStorage.getItem('roleName') === 'Super Admin') {
          navigate('/superadmin/dashboard');
        } else if (localStorage.getItem('roleName') === 'Admin') {
          navigate('/admin/dashboard');
        } else if (localStorage.getItem('roleName') === 'Customer') {
          navigate('/customer/dashboard');
        }
      } else {
        navigate('/login');
      }
    } else {
      console.log('test');
      navigate('/login');
    }
  }, []);
  function CheckExpirationTime() {
    if (localStorage.getItem('tokenexpiration') !== '') {
      const expiredate = new Date(localStorage.getItem('tokenexpiration'));
      const localdate = new Date();
      if (expiredate > localdate) {
        return true;
      }
    }
    return false;
  }
  const applyErrorClass = (field) =>
    field in errors && errors[field] == false ? ' form-control-danger' : '';
  return (

    <div className="">
      <main className="homeSection" style={{backgroundImage:"/assets/images/background.jpg"}}>
        <img src="/assets/images/logo.jpg" className="homelogo" />
        <form onSubmit={handleSubmit} autoComplete="off" noValidate className="loginForm">
          <div className="inputDivs">
            <span className="material-symbols-outlined"> <i className="ri-mail-line" /> </span>
            <input className={'loginInputs' + applyErrorClass('email')} name="email" type="text" value={values.email} onChange={handleInputChange} placeholder="Please enter your email" />
          </div>
          <div className="inputDivs">
            <span className="material-symbols-outlined"> <i className="ri-lock-line" /> </span>
            <input type="password" className={'loginInputs' + applyErrorClass('password')} name="password" value={values.password} onChange={handleInputChange} placeholder="Please enter password" />
          </div>
          {loading===false?
          <button className="LoginBtn">Login to my account</button>:
          <button className="LoginBtn" disabled>Please wait...</button>}
          <Link to={"/register"} className="ClientRegisterBtn">Register as a client</Link>
          {/* <Link to={"/collector-register"} className="CollectorRegisterBtn">Register as a collector</Link> */}
        </form>
      </main>
    </div>
  );
}
