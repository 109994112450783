export const APIConfig = {
  APILOCALURL: 'https://localhost:7114/api/',
  APISERVERURL: 'https://infinitywebapi.azurewebsites.net/api/',
  APIACTIVATEURL: 'https://infinitywebapi.azurewebsites.net/api/',

  GETCLIENTDASHBOARD: 'Dashboard/GetClient',
  GETCOLLECTORDASHBOARD: 'Dashboard/GetCollector',
  GETINFINITYDASHBOARD: 'Dashboard/GetInfinity',

  GETALLGROUPTYPE: 'GroupType/GetAll',
  GETGROUPTYPE: 'GroupType',
  CREATEGROUPTYPE: 'GroupType/Create',
  UPDATEGROUPTYPE: 'GroupType',
  DELETEGROUPTYPE: 'GroupType',
  GETGROUPTYPEBYID: 'GroupType/GetById',

  GETALLLEADS: 'Lead/GetAll',
  GETLEADS: 'Lead',
  GETLEADSBYSTATUS: 'Lead/GetByStatus',
  CREATELEAD: 'Lead/Create',
  UPDATELEAD: 'Lead',
  DELETELEAD: 'Lead',

  GETALLINVOICES: 'Invoice/GetAll',
  GETINVOICES: 'Invoice',
  GETINVOICEBYREQUEST: 'Invoice/GetByRequestId',
  CREATEINVOICE: 'Invoice/Create',
  UPDATEINVOICE: 'Invoice',
  DELETEINVOICE: 'Invoice',

  GETALLITEM: 'Item/GetAll',
  GETITEM: 'Item',
  CREATEITEM: 'Item/Create',
  UPDATEITEM: 'Item',
  DELETEITEM: 'Item',
  GETITEMBYID: 'Item/GetById',

  GETALLLOGISTIC: 'Logistic/GetAll',
  GETLOGISTIC: 'Logistic',
  CREATELOGISTIC: 'Logistic/Create',
  UPDATELOGISTIC: 'Logistic',
  DELETELOGISTIC: 'Logistic',
  GETLOGISTICBYID: 'Logistic/GetById',

  GETALLRECYCLEITEM: 'RecycleItem/GetAll',
  GETRECYCLEITEM: 'RecycleItem',
  CREATERECYCLEITEM: 'RecycleItem/Create',
  UPDATERECYCLEITEM: 'RecycleItem',
  DELETERECYCLEITEM: 'RecycleItem',
  GETRECYCLEITEMBYID: 'RecycleItem/GetById',

  CREATEREQUESTRECYCLE: 'RequestRecycle/Create',
  UPDATEREQUESTRECYCLE: 'RequestRecycle',
  DELETEREQUESTRECYCLE: 'RequestRecycle',
  GETREQUESTRECYCLEBYREQUESTID: 'RequestRecycle/GetByRequestId',

  GETALLCLIENT: 'Client/GetAll',
  GETCLIENT: 'Client',
  CREATECLIENT: 'Client/Create',
  UPDATECLIENT: 'Client',
  DELETECLIENT: 'Client',
  GETCLIENTBYID: 'Client/GetById',

  GETALLBRANCH: 'Branch/GetAll',
  GETBYGROUPTTYPE: 'Branch/GetByGroupType',
  GETBYINFINITY: 'Branch/GetByInfinity',
  GETBYCLIENTREFERENCE: 'Branch/GetByClient',
  GETBYCOLLECTORREFERENCE: 'Branch/GetByCollector',
  GETBYBUYERREFERENCE: 'Branch/GetByBuyer',
  GETBRANCH: 'Branch',
  CREATEBRANCH: 'Branch/Create',
  UPDATEBRANCH: 'Branch',
  DELETEBRANCH: 'Branch',
  GETBRANCHBYID: 'Branch/GetById',
  GETBRANCHCLIENTUSERS: 'Branch/GetClientUsers',
  GETBRANCHBUYERUSERS: 'Branch/GetBuyerUsers',
  GETBRANCHCOLLECTORUSERS: 'Branch/GetCollectorUsers',

  GETALLCOLLECTOR: 'Collector/GetAll',
  GETCOLLECTOR: 'Collector',
  CREATECOLLECTOR: 'Collector/Create',
  UPDATECOLLECTOR: 'Collector',
  DELETECOLLECTOR: 'Collector',
  GETCOLLECTORBYID: 'Collector/GetById',
  GETCOLLECTORUSERS: 'Collector/GetUsers',

  GETALLBUYER: 'Buyer/GetAll',
  GETBUYER: 'Buyer',
  CREATEBUYER: 'Buyer/Create',
  UPDATEBUYER: 'Buyer',
  DELETEBUYER: 'Buyer',
  GETBUYERBYID: 'Buyer/GetById',

  GETALLGROUP: 'Group/GetAll',
  GETALLBYTYPE: 'Group/GetAllByType',
  GETGROUP: 'Group',
  CREATEGROUP: 'Group/Create',
  UPDATEGROUP: 'Group',
  DELETEGROUP: 'Group',
  GETGROUPBYID: 'Group/GetById',

  GETCLIENTUSERREQUESTS: 'Request/ClientUser',
  GETREQUESTS: 'Request',
  GETCOLLECTORREQUESTS: 'Request/Collector',
  GETCOLLECTORUSERREQUESTS: 'Request/CollectorUser',
  CREATEREQUEST: 'Request/Create',
  UPDATEREQUEST: 'Request',
  DELETEREQUEST: 'Request',
  GETREQUESTBYID: 'Request',
  GETREQUESTVIEWBYID: 'Request/InfinityView',
  GETREQUESTCOLLECTORVIEWBYID: 'Request/CollectorView',
  ASSIGNREQUESTTOCOLLECTOR: 'Request/AssignCollector',
  ASSIGNREQUESTTOPICKUP: 'Request/AssignLogisitic',
  ASSIGNREQUESTTODRIVER: 'Request/AssignDriver',
  REQUESTITEMDELIVERED: 'Request/Delivered',
  REQUESTITEMRECYCLED: 'Request/Recycled',
  REQUESTITEMSOLD: 'Request/Sold',
  COLLECTREQUEST: 'Request/Collected',
  COLLECTREQUESTDETAILS: 'Request/Collected',
  GETCLIENTREQUESTS: 'Request/Client',
  
  LOGINUSER: 'Home/Login',
  CREATEUSER:'User/create',
  CHANGEPASSWORD: 'User/ChangePassword',
  GETUSERBYID: 'User/GetById',
  DELETEUSER: 'User',
  USERSTATUS: 'User/Status',
  UPDATEUSERPROFILE: 'User',
  GETINFINITYUSERS: 'User/GetInfinityUsers',

  GETINFINITYCOUNT :'Dashboard/GetInfinity',
  GETCLIENTCOUNT :'Dashboard/GetClient',
  GETCOLLECTORCOUNT :'Dashboard/GetCollector',
  GETBUYERCOUNT :'Dashboard/GetBuyer',
  GETCO2COUNT :'Dashboard/GetCO2',

  GETINFINITYREPORT:'Report/GetRequests',
  GETCLIENTREPORT:'Report/GetClientRequests',

  CREATEPICKUPREQUEST :'PickupRequest/Create',
  GETCLIENTPICKUPREQUESTS :'PickupRequest/Client',
  GETALLPICKUPREQUESTS :'PickupRequest',
  GETPICKUPREQUESTBYID :'PickupRequest',
  ASSIGNLOGISTICPICKUPREQUEST :'PickupRequest/AssignLogisitic',
  PICKUPREQUESTSTATUS :'PickupRequest/Status',
  PICKUPREQUESTTRACKING :'PickupRequest/Tracking',
  DELETEEPICKUPREQUEST: 'PickupRequest',
}