import Footer from '../Common/Layouts/Footer';
import Header from '../Common/Layouts/Header';
import SideBar from '../Common/Layouts/SideBar';
import CountUp from 'react-countup';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { APIConfig } from '../Common/Configurations/APIConfig';
import { Link } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";
export default function ClientDashboard() {
  const [branches, setBranches] = useState(0);
  const [users, setUsers] = useState(0);
  const [requests, setRequests] = useState(0);
  const [requestCollected, setRequestedCollected] = useState(0);
  const [co2, setCo2] = useState(0);
  const [branchLabels, setBranchLabels] = useState([]);
  const [branchSeries, setBranchSeries] = useState([]);
  const [collectedSeries, setCollected] = useState([]);
  const headerconfig = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      'Content-Type': 'application/json',
    },
  };
  const MatsRecycledGraph = () => {
    var options = {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            console.log(1)
          }          
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: branchLabels,
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      }
    };
    var series = [{
      data: branchSeries
    }]
    return (
      <ReactApexChart
        className="apex-charts"
        series={series}
        options={options}
        type="bar"
        height={267.7}
      />
    )
  }
  const RequestsGraph = () => {
    var options = {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            console.log(1)
          }
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: ['NEW','COLLECTOR ASSIGNED','DRIVER ASSIGNED','COLLECTED'],
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      }
    };
    var series = [{
      data: collectedSeries
    }]
    return (
      <ReactApexChart
        className="apex-charts"
        series={series}
        options={options}
        type="bar"
        height={267.7}
      />
    )
  }
  const GetDataCounts = () => {
    axios
      .get(APIConfig.APIACTIVATEURL + APIConfig.GETCLIENTCOUNT+"?ReferenceId="+localStorage.getItem('referenceId'), {
        ...headerconfig,
      })
      .then((response) => {
        setBranches(response.data.clientBranches);
        setUsers(response.data.clientUsers);
        setRequests(response.data.clientRequests);
        setRequestedCollected(response.data.clientCollectedRequests);
        setBranchLabels(response.data.branchLabels);
        setBranchSeries(response.data.branchRequestSeries);
        setCollected(response.data.branchCollectedSeries);
      });
  };
  const formatNumber = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`.replace('.0', '');
    }
    return value.toString();
  };
  const GetCO2Data = () => {
    axios
      .get(APIConfig.APIACTIVATEURL + APIConfig.GETCO2COUNT+"?ReferenceId="+localStorage.getItem('referenceId')+"&UserType=CLIENT", {
        ...headerconfig,
      })
      .then((response) => {
        setCo2(formatNumber(response.data.value));
      });
  };
  useEffect(() => {
    GetDataCounts();
    GetCO2Data();
  }, []);
  return (
    <div id="layout-wrapper">
      <Header></Header>
      <SideBar></SideBar>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Hello, {localStorage.getItem("name")}</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                      <div className="col" style={{backgroundColor:"#85C300"}}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{color:"#fff"}}>Branches</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{color:"#fff"}}>
                              <i className="ri-store-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{color:"#fff"}}>{branches}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{backgroundColor:"#85C300"}}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{color:"#fff"}}>Branch Users</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{color:"#fff"}}>
                              <i className="ri-user-2-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{color:"#fff"}}>{users}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{backgroundColor:"#85C300"}}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{color:"#fff"}}>Requests</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{color:"#fff"}}>
                              <i className="ri-message-3-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{color:"#fff"}}>{requests}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{backgroundColor:"#85C300"}}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{color:"#fff"}}>Requests Collected</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{color:"#fff"}}>
                              <i className="ri-truck-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{color:"#fff"}}>{requestCollected}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{backgroundColor:"#85C300"}}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{color:"#fff"}}>CO2 Reduction</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0"style={{color:"#fff"}}>
                              <i className="ri-team-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{color:"#fff"}}>{co2}</h2>
                              <p>Total Mats * Co2 Value</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{/* end row */}
                  </div>{/* end card body */}
                </div>{/* end card */}
              </div>{/* end col */}
            </div>{/* end row */}
            <div className="row">
              <div className="col-xxl-6 col-md-6">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Mats recycled by whole company</h4>
                  </div>{/* end card header */}
                  <div className="card-body pb-0">
                    <MatsRecycledGraph type="bar" height={350} />
                  </div>
                </div>{/* end card */}
              </div>
              <div className="col-xxl-6 col-md-6">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Collections made by whole company</h4>
                  </div>{/* end card header */}
                  <div className="card-body pb-0">
                    <RequestsGraph type="bar" height={350} />
                  </div>
                </div>{/* end card */}
              </div>
            </div>
          </div>
          {/* container-fluid */}
        </div>
      </div>
    </div>
  );
}
