import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const initialFieldValues = {
    requestRecycleId: "00000000-0000-0000-0000-000000000000",
    pickupRequestId: "00000000-0000-0000-0000-000000000000",
    recycledItemId: "00000000-0000-0000-0000-000000000000",
    quantity: "",
    weight: ""
};
export default function ReportView() {
    const params = useParams();
    const contentRef = useRef();
    const navigate = useNavigate();
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [request, setRequest] = useState([]);
    const [invoice, setInvoice] = useState(false);
    const [items, setItems] = useState([]);
    const [recycleItems, setRecycleItems] = useState([]);
    const [recycledItems, setRecycledItems] = useState([]);
    const [requestHistory, setRequestHistory] = useState([]);
    const [logisticId, setLogisticId] = useState("00000000-0000-0000-0000-000000000000");
    const [collectors, setCollectors] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [sealNumber, setSealNumber] = useState('');
    const [truckReferenceNumber, setTruckReferenceNumber] = useState('');
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const downloadPDF = () => {
        const input = contentRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('InfinityRequestDetails.pdf');
        });
    };
    const validate = () => {
        let temp = {};
        temp.recycledItemId = values.recycledItemId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.quantity = values.quantity === "" ? false : true;
        temp.weight = values.weight === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("requestRecycleId", values.requestRecycleId)
            formData.append("pickupRequestId", params.pickupRequestId)
            formData.append("recycledItemId", values.recycledItemId)
            formData.append("weight", values.weight)
            formData.append("quantity", values.quantity)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEREQUESTRECYCLE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEREQUESTRECYCLE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('requestRecycleId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetRecycledItems();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetRecycledItems();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(APIConfig.APIACTIVATEURL + APIConfig.DELETEREQUESTRECYCLE + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        resetForm();
                        GetRecycledItems();
                    }
                    else {
                        handleError(response.data.data);
                        resetForm();
                        GetRecycledItems();
                    }
                });
        }
    }
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetRecycledItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTRECYCLEBYREQUESTID + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setRecycledItems(response.data.data.data);
            });
    };
    const GetRecycleItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLRECYCLEITEM, { ...headerconfig })
            .then((response) => {
                setRecycleItems(response.data.data);
            });
    };
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPICKUPREQUESTBYID + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
                if (response.data.data != null) {
                    setItems(response.data.data.pickupRequestItems);
                    setRequestHistory(response.data.data.requestHistory);
                    setRecycledItems(response.data.data.pickupRecycleItems);
                }
            });
    };
    const AssignCollector = (e, requestId, logisticId) => {
        e.preventDefault();
        if (logisticId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select collector");
        }
        else if (window.confirm('Are you sure to assign this collector?')) {
            const formData = {
                "pickupRequestId": requestId,
                "logisticId": logisticId,
                "sealNumber": sealNumber,
                "truckReferenceNumber": truckReferenceNumber
            }
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.ASSIGNLOGISTICPICKUPREQUEST, formData, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        setBtnSubmit(false)
                        navigate('/pickuprequests')
                    }
                    else {
                        handleError(response.data.data);
                    }
                });
        }
    }
    const RequestStatus = (e, requestId, Status) => {
        e.preventDefault();
        if (window.confirm('Are you sure to update the status?')) {
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.PICKUPREQUESTSTATUS + "?PickupRequestId=" + requestId + "&Status=" + Status, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        setBtnSubmit(false)
                        navigate('/pickuprequests')
                    }
                    else {
                        handleError(response.data.data);
                    }
                });
        }
    }
    const handleCollectorChange = (e) => {
        const { name, value } = e.target;
        setLogisticId(value);
    };
    const GetLogistics = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLLOGISTIC, { ...headerconfig })
            .then((response) => {
                setCollectors(response.data.data);
            });
    };
    const GetInvoice = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETINVOICEBYREQUEST + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                console.log(response.data)
                if (response.data.data != "No Records") {
                    setInvoice(true);
                }
                else {
                    setInvoice(false);
                }
            });
    };
    useEffect(() => {
        GetRequest();
        GetLogistics();
        GetRecycleItems();
        GetInvoice();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row g-4 align-items-center">
                                    <div className="col-sm">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button className="btn btn-secondary add-btn" onClick={downloadPDF}> Download</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div ref={contentRef}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-header">
                                            <div className="row g-4 align-items-center">
                                                <div className="col-sm">
                                                    <h4 className="mb-sm-0">Request Details</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row text-nowrap p-3">
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <ul className="list-group">
                                                            <li className="list-group-item active">Client Details</li>
                                                            <li className="list-group-item">Client : {request.clientName}</li>
                                                            <li className="list-group-item">Phone : {request.userPhoneNumber}</li>
                                                            <li className="list-group-item">Name : {request.userName}</li>
                                                            <li className="list-group-item">Email : {request.userEmail}</li>
                                                            <li className="list-group-item">Address : {request.address}</li>
                                                            <li className="list-group-item">Pickup Date : {moment(moment.utc(request.pickupRequestedDate).toDate()).format('DD MMM YYYY')}</li>
                                                            <li className="list-group-item">Status : <span className="badge bg-success">{request.status}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {request.status !== "NEW" ?
                                                    <div className="col-lg-12">
                                                        <div className="mb-3">
                                                            <ul className="list-group">
                                                                <li className="list-group-item active">Collector Details : </li>
                                                                <li className="list-group-item">Collector : {request.logisticName}</li>
                                                                <li className="list-group-item">Phone : {request.logisticPhoneNumber}</li>
                                                                <li className="list-group-item">Email : {request.logisticEmail}</li>
                                                                <li className="list-group-item">Truck Reference Number : {request.truckReferenceNumber}</li>
                                                                <li className="list-group-item">Seal Number : {request.sealNumber}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    :
                                                    request.status === "NEW" ?
                                                        <div className="col-lg-12">
                                                            <div className="mb-3">
                                                                <ul className="list-group">
                                                                    <li className="list-group-item active">Collector Details : Not yet assigned</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        :
                                                        ""}
                                                <div className="col-lg-12">
                                                    <ul className="list-group">
                                                        {request.status === "COLLECTOR ASSIGNED" ?
                                                            <li className="list-group-item">
                                                                Is Items Collected? : <button onClick={e => RequestStatus(e, request.pickupRequestId, "COLLECTED")} className="btn btn-success add-btn"> YES</button>
                                                            </li>
                                                            :
                                                            localStorage.getItem('roleName') === "Infinity" && request.status === "COLLECTED" ?
                                                                <li className="list-group-item">
                                                                    Is Items Reached Plant? : <button onClick={e => RequestStatus(e, request.pickupRequestId, "DELIVERED")} className="btn btn-success add-btn"> YES</button>
                                                                </li>
                                                                :
                                                                localStorage.getItem('roleName') === "Infinity" && request.status === "DELIVERED" ?
                                                                    <li className="list-group-item">
                                                                        Is Items Recycled? : <button onClick={e => RequestStatus(e, request.pickupRequestId, "RECYCLED")} className="btn btn-success add-btn"> YES</button>
                                                                    </li>
                                                                    :
                                                                    <li className="list-group-item">

                                                                    </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div class="card-header align-items-center d-flex">
                                                <h4 class="card-title mb-0 flex-grow-1">Pickup Items</h4>
                                            </div>
                                            <div className="card-body p-0">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Item Name</th>
                                                            <th>Pallets</th>
                                                            <th>Weight</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {items.length > 0 && items.map(i =>
                                                            <tr>
                                                                <td>{i.itemName}</td>
                                                                <td>{i.quantity}</td>
                                                                <td>{i.weight}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div class="card-header align-items-center d-flex">
                                                <h4 class="card-title mb-0 flex-grow-1">Recycled Items</h4>
                                            </div>
                                            <div className="card-body p-0">
                                                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                                    <table className="table align-middle" id="customerTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Item Name</th>
                                                                <th>Pallets</th>
                                                                <th>Weight</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {recycledItems.length > 0 && recycledItems.map(i =>
                                                                <tr>
                                                                    <td>{i.itemName}</td>
                                                                    <td>{i.quantity}</td>
                                                                    <td>{i.weight}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body p-0">
                                                <div class="container mt-1" style={{ paddingBottom: "100px" }}>
                                                    <h2 class="text-center">Request Tracking</h2>
                                                    <ul class="timeline">
                                                        {requestHistory.length > 0 && requestHistory.map((rh, index) =>
                                                            <li class="timeline-item">
                                                                <div class="timeline-panel">
                                                                    <div class="timeline-heading">
                                                                        <h5 class="timeline-title">{rh.status}</h5>
                                                                        <p><small class="text-muted">{moment.utc(rh.updatedDate).local().format('DD MMM YYYY hh:mm a')}</small></p>
                                                                    </div>
                                                                    <div class="timeline-body">
                                                                        {rh.status === "NEW" ?
                                                                            <p className="mb-1 mt-2">New pickup request received</p>
                                                                            :
                                                                            rh.status === "COLLECTOR ASSIGNED" ?
                                                                                <p className="mb-1 mt-2">Collector assigned to pickup items</p>
                                                                                :
                                                                                rh.status === "COLLECTED" ?
                                                                                    <h6 className="mb-1 mt-2">Items collected by the collector</h6>
                                                                                    :
                                                                                    rh.status === "DELIVERED" ?
                                                                                        <h6 className="mb-1 mt-2">Items delivered to the plant</h6>
                                                                                        :
                                                                                        rh.status === "RECYCLED" ?
                                                                                            <h6 className="mb-1 mt-2">Items recycled and ready to sale</h6>
                                                                                            :
                                                                                            rh.status === "SOLD" ?
                                                                                                <h6 className="mb-1 mt-2">Items sold to the buyer</h6>
                                                                                                : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}